import React, {useState} from "react";
import {Button, Form, Input, message} from "antd";
import {ApolloError, useMutation} from "@apollo/client";
import UpdateDiscountValue from "../../graphql/mutations/updateDiscountValue";

interface Props {
    discountValueId: number,
    value: any,
    onFinish: any
}

const DiscountValueForm = (props: Props) => {

    const [focus, setFocus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [discountValueForm] = Form.useForm();

    discountValueForm.setFieldsValue({
        value: props.value
    });

    const [updateDiscountValue] = useMutation(UpdateDiscountValue);

    const onFinish = (values: any) => {
        setLoading(true)
        updateDiscountValue({
            variables: {
                discountValueId: props.discountValueId,
                input: {
                    value: parseFloat(values.value)
                },
            }
        }).then((result) => {
            setFocus(false)
            setLoading(false)
            props.onFinish(result);
        }).catch((err: ApolloError) => {
            setLoading(false)
            return message.error(err.message);
        })
    };

    return <Form onFinish={onFinish} form={discountValueForm}>
        <Input.Group compact>
            <Form.Item name='value' style={focus ? {width: 'calc(100% - 100px)'} : {width: '100%'}}>
                <Input type='number' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} step='0.01'/>
            </Form.Item>
            {focus ? <Button type="primary" htmlType="submit" style={{width: '100px'}} loading={loading}>Save</Button> : ''}
        </Input.Group>
    </Form>;
}

export default DiscountValueForm;
