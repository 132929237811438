import React from "react";
import {Select} from "antd";
import {useQuery} from "@apollo/client";
import CurrenciesQuery from "../../graphql/queries/currencies";
import CountriesQuery from "../../graphql/queries/countries";
import DiscountCodesQuery from "../../graphql/queries/discountCodeList";
import _ from "lodash";
import {SelectProps} from "antd/lib/select";

export const CurrencySelect = (props: SelectProps) => {

    const currenciesQuery = useQuery(CurrenciesQuery, {
        variables: {}
    });

    return <Select
        loading={currenciesQuery.loading}
        placeholder="Select the currency"
        options={_.get(currenciesQuery, 'data.__type.enumValues', []).map((item: any) => {
            return {
                value: item.name,
                label: item.description
            }
        })}
        {...props}
    />;
}

export const CountrySelect = (props: SelectProps) => {

    const countriesQuery = useQuery(CountriesQuery, {
        variables: {}
    });

    return <Select
        loading={countriesQuery.loading}
        showSearch={true}
        placeholder="Select a country"
        optionFilterProp={"label"}
        options={_.get(countriesQuery, 'data.__type.enumValues', []).map((item: any) => {
            return {
                value: item.name,
                label: item.description
            }
        })}
        {...props}
    />;
}

export const DiscountCodeSelect = (props: SelectProps) => {

    const discountCodesQuery = useQuery(DiscountCodesQuery, {
        variables: {}
    });

    return <Select
        loading={discountCodesQuery.loading}
        placeholder="Select the discount code"
        showSearch={true}
        filterOption={(input, option: any) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={_.get(discountCodesQuery, 'data.discountCodeList.discountCodes', []).map((item: any) => {
            return {
                value: item.id,
                label: item.code + " (" + item.name + ")"
            }
        })}
        {...props}
    />;
}