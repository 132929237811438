export enum UserActionType {
    SET_USER = "SET_USER",
    RESET_USER = "RESET_USER"
}

export interface UserAction {
    type: UserActionType,
    payload?: string
}

const initialState = null;

export default function (state: string | null = initialState, action: UserAction) {
    switch (action.type) {
        case "SET_USER": {
            return action.payload;
        }

        case "RESET_USER": {
            return initialState;
        }

        default:
            return state;
    }
}