import gql from "graphql-tag";

export default gql`
    mutation ($discountId: ID!, $import: String!){
        importDiscount (discountId: $discountId, import: $import) {
            success
            message
        }
    }
`;
