import React, {useEffect, useState} from "react";
import {Spin} from "antd";
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../redux/selectors/user";
import KeycloakService from "../service/keycloak";

interface Props {
    keycloak: KeycloakService,
    children?: any
}

const KeycloakProvider = ({keycloak, children}: Props) => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const setUser = (token: string) => {
        let payload = jwt_decode(token);

        dispatch({
            type: 'SET_USER',
            payload: {
                accessToken: keycloak.getToken(),
                name: _.get(payload, 'name'),
                email: _.get(payload, 'email'),
                roles: _.get(payload, 'realm_access.roles', [])
            }
        });
    }

    useEffect((): any => {
        keycloak.init({
            onLoad: 'login-required'
        }).then((authenticated?: any) => {
            if (authenticated) {
                let token = keycloak.getToken();

                if (typeof token === 'string') {
                    setUser(token);
                }

                setLoading(false);
            }
        }).catch((__err: any) => {
            return setLoading(false);
        });
    }, [])

    keycloak.onTokenExpired(() => {
        keycloak.updateToken(60).then((refreshed: any) => {
            if (refreshed) {
                console.log('token refreshed');
                let token = keycloak.getToken();
                if (typeof token === 'string') {
                    setUser(token);
                }
            } else {
                console.log('token not refreshed ' + new Date());
                dispatch({
                    type: 'RESET_USER',
                });
                window.location.reload();
            }
        }).catch(() => {
            console.error('Failed to refresh token ' + new Date());
            dispatch({
                type: 'RESET_USER',
            });
            window.location.reload();
        });
    });

    if (loading) {
        return <div style={{marginTop: 200, marginBottom: 100, textAlign: "center"}}>
            <Spin size="large" />
        </div>;
    }

    return children({user: user});
}

export default KeycloakProvider;