import React  from 'react';
import {Layout} from "antd";
import HeaderPanel from "../HeaderPanel";
const { Content } = Layout

type Props = {
    children: any,
    logo?: any,
    navigationMenuConfig?: any,
    routerBreadcrumb?: any
};

const LayoutBody = (props: Props) => {

    return <Layout className={"layout full-height"} >
        <HeaderPanel navigationMenuConfig={props.navigationMenuConfig}/>
        <Content style={{ padding: '24px' }}>
        {props.children}
        </Content>
    </Layout>;
};

export default LayoutBody;
