import React, {useCallback, useState} from "react";
import {Button, Col, Form, Input, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import ProductListListQuery from "./../../graphql/queries/productList";
import * as _ from "lodash";
import {ApolloError, useMutation, useQuery} from "@apollo/client";
import CurrenciesQuery from "../../graphql/queries/currencies";
import {useHistory} from "react-router-dom";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import DeleteProduct from "../../graphql/mutations/deleteProduct";
import ProductForm from "../ProductForm";
import UpdateProduct from "../../graphql/mutations/updateProduct";

interface Props {
}

export interface Product {
    id: string,
    catalogNo: string
    name: string
    manually: boolean
}

interface Filter {
    currency: Array<string>
}

const ProductList = (props: Props) => {

    const [searchValue, setSearchValue] = useState('');
    const [currentProduct, setCurrentProduct] = useState<Product | undefined>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');
    const [start, setStart] = useState(0);
    const [limit, setLimit] = useState(25);
    const [filter, setFilter] = useState<Filter>({
        currency: ["EUR"]
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [updateProductForm] = Form.useForm();

    const history = useHistory();

    const [updateProduct] = useMutation(UpdateProduct);
    const [deleteProduct] = useMutation(DeleteProduct);

    const handler = useCallback(_.debounce((value) => {
        setCurrentPage(1);
        setStart(0);
        setQuery(value);
    }, 500), []);

    const showModal = (product: Product) => {
        setCurrentProduct(product);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        updateProductForm
            .validateFields()
            .then(values => {
                if (currentProduct) {
                    updateProduct({
                        variables: {
                            productId: currentProduct.id,
                            input: values,
                        }
                    }).then((result) => {
                        updateProductForm.resetFields();
                        setIsModalVisible(false);
                        return productListQuery.refetch();
                    }).catch((err: ApolloError) => {
                        return message.error(err.message);
                    })
                }
            })
            .catch(info => {
                return message.warning(info);
            });
    };

    const handleCancel = () => {
        updateProductForm.resetFields();
        setIsModalVisible(false);
    };

    const productListQuery = useQuery(ProductListListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: start,
            limit: limit,
            search: query,
            filter: filter,
        }
    });

    const currenciesQuery = useQuery(CurrenciesQuery, {
        variables: {}
    });

    return <div>
        <Row style={{margin: '0 0 20px 0'}}>
            <Col span={18}>
                <Button type="primary" onClick={() => {
                    history.push('/products/create');
                }}>Create Product</Button>
            </Col>
            <Col span={6}>
                <Input placeholder="Search Product" onChange={(e) => {
                    handler(e.target.value);
                    setSearchValue(e.target.value)
                }} value={searchValue}/>
            </Col>
        </Row>

        <Modal title="Update Product" onOk={handleOk} onCancel={handleCancel} visible={isModalVisible} getContainer={false}>
            <ProductForm layout={{labelCol: {span: 6}, wrapperCol: {span: 18}}} form={updateProductForm} product={currentProduct}/>
        </Modal>

        <Table
            loading={productListQuery.loading || currenciesQuery.loading}
            pagination={{
                size: 'small',
                defaultPageSize: limit,
                total: _.get(productListQuery, 'data.productList.total', []),
                current: currentPage,
                onChange: (page, pageSize) => {
                    pageSize = (typeof pageSize !== 'undefined') ? pageSize : limit;
                    setCurrentPage(page);
                    setStart(page * pageSize - pageSize);
                    setLimit(pageSize);
                }
            }}
            dataSource={_.get(productListQuery, 'data.productList.products', [])}
            rowKey={'id'}
            columns={[{
                width: 200,
                title: 'Catalog No',
                key: 'catalogNo',
                dataIndex: 'catalogNo',
            }, {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
            }, {
                width: 150,
                title: 'Discount Code',
                key: 'discountCode',
                dataIndex: ['discountCode', 'code']
            }, {
                width: 150,
                title: 'Currency',
                key: 'currency',
                dataIndex: 'currency',
                filterMultiple: false,
                filters: _.get(currenciesQuery, 'data.__type.enumValues', []).map((item: any) => {
                    return {
                        text: item.name,
                        value: item.name,
                    }
                }),
                defaultFilteredValue: ['EUR'],
            }, {
                width: 150,
                title: 'Price',
                key: 'price',
                dataIndex: 'price',
                render: (price, record) => {
                    return price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: record.currency
                    })
                }

            }, {
                width: 100,
                render: (text, record: Product) => record.manually ? <Space>
                    <Button type='default' onClick={() => showModal(record)} icon={<EditOutlined/>}></Button>
                    <Popconfirm
                        title="Are you sure to delete this product?"
                        onConfirm={() => {
                            deleteProduct({
                                variables: {
                                    productId: record.id,
                                }
                            }).then(() => {
                                return productListQuery.refetch();
                            }).catch((err: ApolloError) => {
                                return message.error(err.message);
                            })
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger icon={<DeleteOutlined/>}></Button>
                    </Popconfirm>
                </Space> : null
            }]}
            onChange={(pagination, filters: any, sorter, extra) => {
                if (extra.action === 'filter') {
                    setFilter(filters)
                }
            }}
        />
    </div>;
}

export default ProductList;
