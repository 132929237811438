import React, {useCallback, useState} from "react";
import {Badge, Button, Col, Empty, Form, Input, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import DiscountListQuery from "./../../graphql/queries/discountList";
import * as _ from "lodash";
import {ApolloError, useMutation, useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import DeleteDiscount from "../../graphql/mutations/deleteDiscount";
import {DeleteOutlined, DollarOutlined, EditOutlined, UnorderedListOutlined} from '@ant-design/icons';
import DiscountForm from "../DiscountForm";
import UpdateDiscount from "../../graphql/mutations/updateDiscount";

export interface Discount {
    id: string
    name: string
}

interface Props {
}

const DiscountList = (props: Props) => {

    const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useState<string>('');
    const [start, setStart] = useState(0);
    const [limit, setLimit] = useState(25);
    const [currentDiscount, setCurrentDiscount] = useState<Discount | undefined>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [updateDiscountForm] = Form.useForm();

    const history = useHistory();

    const [updateDiscount] = useMutation(UpdateDiscount);
    const [deleteDiscount] = useMutation(DeleteDiscount);

    const handler = useCallback(_.debounce((value) => {
        setCurrentPage(1);
        setStart(0);
        setQuery(value);
    }, 500), []);

    const showModal = (discount: Discount) => {
        setCurrentDiscount(discount);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        updateDiscountForm
            .validateFields()
            .then(values => {
                if (currentDiscount) {
                    updateDiscount({
                        variables: {
                            discountId: currentDiscount.id,
                            input: values,
                        }
                    }).then((result) => {
                        updateDiscountForm.resetFields();
                        setIsModalVisible(false);
                        return discountListQuery.refetch();
                    }).catch((err: ApolloError) => {
                        return message.error(err.message);
                    })
                }
            })
            .catch(info => {
                return message.warning(info);
            });
    };

    const handleCancel = () => {
        updateDiscountForm.resetFields();
        setIsModalVisible(false);
    };

    const discountListQuery = useQuery(DiscountListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: start,
            limit: limit,
            search: query,
        }
    })

    return <div>
        <Row style={{margin: '0 0 20px 0'}}>
            <Col span={18}>
                <Button type="primary" onClick={() => {
                    history.push('/discounts/create');
                }}>Create Discount</Button>
            </Col>
            <Col span={6}>
                <Input placeholder="Search Discount" onChange={(e) => {
                    handler(e.target.value);
                    setSearchValue(e.target.value)
                }} value={searchValue}/>
            </Col>
        </Row>

        <Modal title="Update Discount" onOk={handleOk} onCancel={handleCancel} visible={isModalVisible} getContainer={false}>
            <DiscountForm layout={{labelCol: {span: 6}, wrapperCol: {span: 18}}} form={updateDiscountForm} discount={currentDiscount}/>
        </Modal>

        <Table
            loading={discountListQuery.loading}
            locale={{
                emptyText: <Empty description="No Discount"/>
            }}
            pagination={{
                size: 'small',
                defaultPageSize: limit,
                total: _.get(discountListQuery, 'data.discountList.total', []),
                current: currentPage,
                onChange: (page, pageSize) => {
                    pageSize = (typeof pageSize !== 'undefined') ? pageSize : limit;
                    setCurrentPage(page);
                    setStart(page * pageSize - pageSize);
                    setLimit(pageSize);
                }
            }}
            dataSource={_.get(discountListQuery, 'data.discountList.discounts', [])}
            rowKey={'id'}
            columns={[{
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
            }, {
                width: 200,
                render: (text, record) => <Space>
                    <Badge dot={_.get(record, 'productPriceList.total', 0) > 0}>
                        <Button type='default' onClick={() => {
                            history.push('/discounts/' + record.id + '/product-prices')
                        }} icon={<DollarOutlined/>}></Button>
                    </Badge>
                    <Button type='default' onClick={() => {
                        history.push('/discounts/' + record.id + '/discount-values')
                    }} icon={<UnorderedListOutlined/>}></Button>
                    <Button type='default' onClick={() => showModal(record)} icon={<EditOutlined/>}></Button>
                    <Popconfirm
                        title="Are you sure to delete this discount?"
                        onConfirm={() => {
                            deleteDiscount({
                                variables: {
                                    discountId: record.id,
                                }
                            }).then(() => {
                                return discountListQuery.refetch();
                            }).catch((err: ApolloError) => {
                                return message.error(err.message);
                            })
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger icon={<DeleteOutlined/>}></Button>
                    </Popconfirm>
                </Space>
            }]}
        />
    </div>;
}

export default DiscountList;
