import React from "react";
import {Button, Form, FormInstance, Input} from "antd";
import * as _ from "lodash";
import {Discount} from "../DiscountList";

interface FormLayout {
    labelCol: object,
    wrapperCol: object
}

interface Props {
    form: FormInstance,
    discount?: Discount,
    onFinish?: Function,
    submit?: boolean,
    layout: FormLayout
}

const DiscountForm = (props: Props) => {

    const form = props.form;

    let initialValue = {};

    if (props.discount) {
        initialValue = {
            name: _.get(props, 'discount.name'),
        };
    }

    const onFinish = (values: any) => {
        if (typeof props.onFinish === 'function') {
            props.onFinish(values);
        }
    };

    return <Form {...props.layout} form={form} onFinish={onFinish} initialValues={initialValue}>
        <Form.Item label="Name" name='name' rules={[{required: true, message: 'Please input the discount name!'}]}>
            <Input/>
        </Form.Item>
        {props.submit ? <Form.Item wrapperCol={{offset: 9, span: 7}}>
            <Button type="primary" htmlType="submit">
                Create Discount
            </Button>
        </Form.Item> : ''}
    </Form>;
}

export default DiscountForm;
