import React from 'react';
import {Layout} from "antd";

const {Header} = Layout;

type Props = {
    navigationMenuConfig?: any,
};

const HeaderPanel = (props: Props) => {
    return <Header>
        {props.navigationMenuConfig}
    </Header>
};

export default HeaderPanel;
