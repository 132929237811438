import gql from "graphql-tag";

export default gql`
    mutation ($discountValueId: ID!, $input: DiscountValueInput!) {
        updateDiscountValue (discountValueId: $discountValueId, input: $input) {
            id
            insert
            update
        }
    }
`;
