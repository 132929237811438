import React, {useState} from "react";
import {Form, Modal, message, Button} from "antd";
import {ApolloError, useMutation} from "@apollo/client";
import TextArea from "antd/es/input/TextArea";
import ImportDiscount from "../../graphql/mutations/importDiscount";

interface Props {
    discountId: string
    modalVisible: boolean
    onCancel: any
    onFinish: any
}

const DiscountValueImportModal = (props: Props) => {

    const [importForm] = Form.useForm();
    const [importDiscount] = useMutation(ImportDiscount);
    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
        importDiscount({
            variables: {
                discountId: props.discountId,
                import: values.import
            }
        }).then((result) => {
            importForm.resetFields();
            props.onFinish(result);
        }).catch((err: ApolloError) => {
            setLoading(false);
            return message.error(err.message);
        })
    };

    return <Modal title="Import discounts" visible={props.modalVisible} onCancel={() => props.onCancel()} footer={[
        <Button key="back" onClick={props.onCancel}>
            Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => {
            setLoading(true);
            importForm.submit();
        }}>
            Import
        </Button>,
    ]}>
        <p>Copy your discount codes in key, value format into the textarea.</p>
        <Form form={importForm} onFinish={onFinish}>
            <Form.Item name='import'>
                <TextArea rows={15}/>
            </Form.Item>
        </Form>
    </Modal>;
}

export default DiscountValueImportModal;
