import React, {useCallback, useState} from "react";
import {Badge, Button, Col, Empty, Form, Input, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import CustomerListQuery from "./../../graphql/queries/customerList";
import * as _ from "lodash";
import {ApolloError, useMutation, useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import UpdateCustomer from "../../graphql/mutations/updateCustomer";
import DeleteCustomer from "../../graphql/mutations/deleteCustomer";
import {DeleteOutlined, DollarOutlined, EditOutlined, UnorderedListOutlined} from '@ant-design/icons';
import CustomerForm from "../CustomerForm";
import {Discount} from "../DiscountList";
import Moment from 'moment';

Moment.locale('en');

interface Props {
}

export interface Customer {
    id: string
    country: string
    customerId: string
    name: string
    discount: Discount
}

const CustomerList = (props: Props) => {

    const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useState<string>('');
    const [start, setStart] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [currentCustomer, setCurrentCustomer] = useState<Customer | undefined>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [updateCustomerForm] = Form.useForm();

    const history = useHistory();

    const [updateCustomer] = useMutation(UpdateCustomer);
    const [deleteCustomer] = useMutation(DeleteCustomer);

    const handler = useCallback(_.debounce((value) => {
        setCurrentPage(1);
        setStart(0);
        setQuery(value);
    }, 500), []);

    const showModal = (customer: Customer) => {
        setCurrentCustomer(customer);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        updateCustomerForm
            .validateFields()
            .then(values => {
                if (currentCustomer) {
                    updateCustomer({
                        variables: {
                            customerId: currentCustomer.id,
                            input: values,
                        }
                    }).then(() => {
                        setIsModalVisible(false);
                        return customerListQuery.refetch();
                    }).catch((err: ApolloError) => {
                        return message.error(err.message);
                    })
                }
            })
            .catch(info => {
                return message.warning(info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const customerListQuery = useQuery(CustomerListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: start,
            limit: limit,
            search: query,
        }
    })

    return <div>
        <Row style={{margin: '0 0 20px 0'}}>
            <Col span={18}>
                <Button type="primary" onClick={() => {
                    history.push('/customers/create');
                }}>Create Customer</Button>
            </Col>
            <Col span={6}>
                <Input placeholder="Search Customer" onChange={(e) => {
                    handler(e.target.value);
                    setSearchValue(e.target.value)
                }} value={searchValue}/>
            </Col>
        </Row>

        <Modal title="Update Customer" onOk={handleOk} onCancel={handleCancel} visible={isModalVisible} getContainer={false}>
            <CustomerForm layout={{labelCol: {span: 6}, wrapperCol: {span: 18}}} form={updateCustomerForm} customer={currentCustomer}/>
        </Modal>

        <Table
            loading={customerListQuery.loading}
            locale={{
                emptyText: <Empty description="No customer found">
                    <Button type="primary" onClick={() => {
                        history.push({
                            pathname: '/customers/create',
                            search: '?customerId=' + searchValue
                        })
                    }}>Create Customer</Button>
                </Empty>
            }}
            pagination={{
                size: 'small',
                defaultPageSize: limit,
                total: _.get(customerListQuery, 'data.customerList.total', []),
                current: currentPage,
                onChange: (page, pageSize) => {
                    pageSize = (typeof pageSize !== 'undefined') ? pageSize : limit;
                    setCurrentPage(page);
                    setStart(page * pageSize - pageSize);
                    setLimit(pageSize);
                }
            }}
            dataSource={_.get(customerListQuery, 'data.customerList.customers', [])}
            rowKey={'id'}
            columns={[{
                width: 200,
                title: 'Customer Id',
                render: (text, record) => {
                    return record.country.toUpperCase() + '-' + record.customerId;
                }
            }, {
                title: 'Name',
                dataIndex: 'name',
                render: (text: string, record) => {
                    return record.active ? <Badge status="success" text={text}/> : <Badge status="error" text={text}/>;
                }
            }, {
                width: 250,
                title: 'Discount',
                key: 'discount',
                dataIndex: ['discount', 'name']
            }, {
                width: 150,
                title: 'Currency',
                key: 'currency',
                dataIndex: 'currency'
            }, {
                width: 150,
                title: 'Last Export',
                dataIndex: 'lastExport',
                render: (text: string, record) => {
                    return text ? Moment(text).format('ll') : '';
                }
            }, {
                width: 150,
                render: (text, record) => <Space>
                    {record.discount ? <Button type='default' onClick={() => {
                        history.push('/discounts/' + record.discount.id + '/discount-values')
                    }} icon={<UnorderedListOutlined/>}></Button> : ''}
                    <Button type='default' onClick={() => showModal(record)} icon={<EditOutlined/>}></Button>
                    <Popconfirm
                        title="Are you sure to delete this customer?"
                        onConfirm={() => {
                            deleteCustomer({
                                variables: {
                                    customerId: record.id,
                                }
                            }).then((result) => {
                                customerListQuery.refetch();
                            }).catch((err: ApolloError) => {
                                return message.error(err.message);
                            })
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger icon={<DeleteOutlined/>}></Button>
                    </Popconfirm>
                </Space>
            }]}
        />
    </div>;
}

export default CustomerList;
