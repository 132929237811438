import React, {useCallback, useState} from "react";
import {Button, Form, FormInstance, Input, Select} from "antd";
import {useQuery} from "@apollo/client";
import * as _ from "lodash";
import {ProductPrice} from "../ProductPriceList";
import {Product} from "../ProductList";
import ProductListQuery from "../../graphql/queries/productList";
import {CurrencySelect} from "../Form";

interface FormLayout {
    labelCol: object,
    wrapperCol: object
}

interface Props {
    form: FormInstance,
    productPrice?: ProductPrice,
    customerId?: string | null,
    discountId?: string | null,
    onFinish?: Function,
    submit?: boolean,
    layout: FormLayout
}

const ProductPriceForm = (props: Props) => {

    const form = props.form;
    const [productSearchValue, setProductSearchValue] = useState('');

    const productListQuery = useQuery(ProductListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: 0,
            limit: 25,
            search: productSearchValue
        }
    })

    let initialValue = {};

    if (props.productPrice) {
        initialValue = {
            productId: _.get(props, 'productPrice.name'),
            price: _.get(props, 'productPrice.price'),
            currency: _.get(props, 'productPrice.currency'),
        };
    }

    const productSearchHandler = useCallback(_.debounce((value) => {
        setProductSearchValue(value);
    }, 500), []);

    const onFinish = (values: any) => {
        if (typeof props.onFinish === 'function') {
            props.onFinish(values);
        }
    };

    return <Form {...props.layout} form={form} onFinish={onFinish} initialValues={initialValue}>
        {!props.productPrice ? <Form.Item label="Catalog No" name='productId' rules={[{required: true, message: 'Please input the catalog no!'}]}>
            <Select
                showSearch={true}
                filterOption={false}
                placeholder="Select a caalog no"
                onSearch={productSearchHandler}
                options={_.get(productListQuery, 'data.productList.products', []).map((product: Product) => {
                    return {
                        value: product.id,
                        label: <div>
                            {product.catalogNo}<br/><small>{product.name}</small>
                        </div>
                    }
                })}
                disabled={!!props.productPrice}
                style={{width: "100%"}}
            />
        </Form.Item> : null}
        <Form.Item label="Price" name='price' rules={[{required: true, message: 'Please input the price!'}]}>
            <Input type='number' step='0.01'/>
        </Form.Item>
        <Form.Item label="Currency" name='currency' rules={[{required: true, message: 'Please input the currency!'}]}>
            <CurrencySelect disabled={!!props.productPrice}/>
        </Form.Item>
        {props.submit ? <Form.Item wrapperCol={{offset: 9, span: 7}}>
            <Button type="primary" htmlType="submit">
                Create Product Price
            </Button>
        </Form.Item> : ''}
    </Form>;
}

export default ProductPriceForm;
