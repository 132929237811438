import React from "react";
import {Form, message, PageHeader} from "antd";
import {useHistory} from "react-router-dom";
import CustomerForm from "../CustomerForm";
import {ApolloError, useMutation} from "@apollo/client";
import CreateCustomer from "../../graphql/mutations/createCustomer";

interface Props {
    urlSearchParams: URLSearchParams
}

const CustomerCreate = (props: Props) => {

    const history = useHistory();
    const customerId = props.urlSearchParams.get('customerId');
    const [createCustomerForm] = Form.useForm();
    const [createCustomer] = useMutation(CreateCustomer);

    return <div>
        <PageHeader
            className="site-page-header"
            onBack={() => {
                history.push('/customers')
            }}
            title="Create Customer"
        />
        <div className="site-layout-content">
            <CustomerForm submit={true} layout={{labelCol: {span: 9}, wrapperCol: {span: 7}}} form={createCustomerForm} customerId={customerId} onFinish={(values: any) => {
                createCustomer({
                    variables: {
                        input: values,
                    }
                }).then((result) => {
                    history.push('/customers')
                }).catch((err: ApolloError) => {
                    return message.error(err.message);
                })
            }}/>
        </div>
    </div>;
}

export default CustomerCreate;
