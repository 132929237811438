import gql from "graphql-tag";

export default gql`
    mutation ($input: DiscountInput!){
        createDiscount (input: $input) {
            id
            insert
            update
            name
        }
    }
`;
