import gql from "graphql-tag";

export default gql`
    mutation ($input: CustomerInput!) {
        createCustomer (input: $input) {
            id
            insert
            update
            country
            customerId
            name
            currency
        }
    }
`;
