import KeycloakJs, {KeycloakError, KeycloakInitOptions, KeycloakLogoutOptions, KeycloakPromise} from 'keycloak-js';

export default class KeycloakService {

    private static instance: KeycloakService;

    private keycloak: KeycloakJs;

    private constructor() {
        this.keycloak = new KeycloakJs({
            'url': 'https://sso.pfeiffer-vacuum.com/auth/',
            'realm': 'pv-services',
            'clientId': 'price-calculator-public'
        });
    }

    public static getInstance(): KeycloakService {
        if (!KeycloakService.instance) {
            KeycloakService.instance = new KeycloakService();
        }

        return KeycloakService.instance;
    }

    public init(initOptions: KeycloakInitOptions): KeycloakPromise<boolean, KeycloakError> {
        return this.keycloak.init(initOptions);
    }

    public logout(options?: KeycloakLogoutOptions) {
        return this.keycloak.logout(options);
    }

    public getToken(): string | undefined {
        return this.keycloak.token;
    }

    public updateToken(minValidity: number): KeycloakPromise<boolean, boolean> {
        return this.keycloak.updateToken(minValidity);
    }

    public onTokenExpired(callback: any) {
        this.keycloak.onTokenExpired = callback;
    }
};