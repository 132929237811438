import React, {useState} from "react";
import {Button, Empty, PageHeader, Spin, Table} from "antd";
import DiscountQuery from "./../../graphql/queries/discount";
import * as _ from "lodash";
import {useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import DiscountValueForm from "../DiscountValueForm";
import DiscountValueImportModal from "../DiscountValueImportModal";
import GenerateDiscountExportButton from "./generateDiscountExportButton";

interface Props {
    discountId: string
}

const DiscountValueList = (props: Props) => {

    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);

    const discountQuery = useQuery(DiscountQuery, {
        fetchPolicy: "network-only",
        variables: {
            discountId: props.discountId,
        }
    })

    if (discountQuery.loading) {
        return <Spin/>
    }

    const discount = _.get(discountQuery, 'data.discount');

    return <div>
        <PageHeader
            className="site-page-header"
            onBack={() => {
                history.goBack();
            }}
            title={_.get(discount, 'name')}
            extra={[
                <Button onClick={() => setModalVisible(true)}>Import</Button>,
                <GenerateDiscountExportButton discountId={props.discountId}/>,
            ]}
        />

        <DiscountValueImportModal discountId={props.discountId} modalVisible={modalVisible} onCancel={() => setModalVisible(false)} onFinish={() => {
            discountQuery.refetch();
            setModalVisible(false);
        }}/>

        <Table
            loading={discountQuery.loading}
            locale={{
                emptyText: <Empty description="No Discount"/>
            }}
            pagination={false}
            dataSource={_.get(discount, 'discountValueList.discountValues', [])}
            rowKey={'id'}
            columns={[{
                width: 50,
                dataIndex: ['discountCode', 'code'],
                title: 'Code',
            }, {
                dataIndex: ['discountCode', 'name'],
                title: 'Name',
            }, {
                width: 300,
                title: 'Discount',
                render: (text, record) => <DiscountValueForm discountValueId={record.id} value={record.value} onFinish={() => {
                    discountQuery.refetch();
                }}/>
            }]}
        />
    </div>;
}

export default DiscountValueList;
