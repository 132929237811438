import React from "react";
import {Form, message, PageHeader} from "antd";
import {useHistory} from "react-router-dom";
import ProductPriceForm from "../ProductPriceForm";
import {ApolloError, useMutation} from "@apollo/client";
import CreateProductPrice from "../../graphql/mutations/createProductPrice";

interface Props {
    backUrl: string,
    customerId?: string,
    discountId?: string,
}

const ProductPriceCreate = (props: Props) => {

    const history = useHistory();
    const [createProductPriceForm] = Form.useForm();
    const [createProductPrice] = useMutation(CreateProductPrice);

    return <div>
        <PageHeader
            className="site-page-header"
            onBack={() => {
                history.push(props.backUrl)
            }}
            title="Product Price"
        />
        <div className="site-layout-content">
            <ProductPriceForm
                submit={true}
                layout={{labelCol: {span: 9}, wrapperCol: {span: 7}}}
                form={createProductPriceForm}
                customerId={props.customerId}
                discountId={props.discountId}
                onFinish={(values: any) => {
                    createProductPrice({
                        variables: {
                            "productId": values.productId,
                            "customerId": props.customerId,
                            "discountId": props.discountId,
                            "input": {
                                "price": parseFloat(values.price),
                                "currency": values.currency,
                            }
                        }
                    }).then(() => {
                        history.push(props.backUrl)
                    }).catch((err: ApolloError) => {
                        return message.error(err.message);
                    })
                }}
            />
        </div>
    </div>;
}

export default ProductPriceCreate;
