import gql from "graphql-tag";

export default gql`
    query ($start: Int, $limit: Int, $search: String) {
        discountList (start: $start, limit: $limit, search: $search) {
            total
            discounts {
                id
                name
                productPriceList {
                    total
                }
            }
        }
    }
`;
