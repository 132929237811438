import React, {Suspense} from "react";
import {HashRouter as Router} from "react-router-dom";
import LayoutBody from "./components/LayoutBody";
import MainNavigation from "./components/MainNavigation";
import Routes from "./routes";
import {ApolloProvider} from '@apollo/client';
import {Button, Result, Spin} from "antd";
import KeycloakProvider from "./provider/keycloak";
import {Provider} from "react-redux";
import store from "./redux/store";
import apolloClient from "./graphql/Client";
import KeycloakService from "./service/keycloak";
import _ from 'lodash';

const Root = () => {

    const spin = <div style={{textAlign: "center"}}>
        <Spin/>
    </div>

    return <Suspense fallback={spin}>
        <div className={'full-height'}>
            <Provider store={store}>
                <KeycloakProvider keycloak={KeycloakService.getInstance()}>
                    {({user}: any) => {
                        if (user.roles.includes('price_calculator')) {
                            const client = apolloClient(user);
                            return <ApolloProvider client={client}>
                                <Router>
                                    <LayoutBody navigationMenuConfig={<MainNavigation/>}>
                                        <Routes/>
                                    </LayoutBody>
                                </Router>
                            </ApolloProvider>
                        } else {
                            return <Result
                                status="403"
                                title="403"
                                subTitle="Sorry, you are not authorized to access this page."
                                extra={<Button type="primary" onClick={() => {
                                    KeycloakService.getInstance().logout();
                                }}>Logout</Button>}
                            />
                        }
                    }}
                </KeycloakProvider>
            </Provider>
            <div style={{textAlign: "center", padding: 10}}>
                © {new Date().getFullYear()} Pfeiffer Vacuum GmbH | Version: {_.get(window, '__VERSION__')}
            </div>
        </div>
    </Suspense>
};

export default Root;
