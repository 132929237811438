import gql from "graphql-tag";

export default gql`
    query ($start: Int, $limit: Int, $search: String, $filter: ProductListFilterInput) {
        productList (start: $start, limit: $limit, search: $search, filter: $filter) {
            total
            products {
                id
                insert
                update
                name
                catalogNo
                discountCode {
                    id
                    code
                }
                price
                currency
                manually
            }
        }
    }
`;
