import gql from "graphql-tag";

export default gql`
    query {
        __type(name: "Country") {
            name
            enumValues {
                name
                description
            }
        }
    }
`;
