import React from "react";
import {Form, message, PageHeader} from "antd";
import {ApolloError, useMutation} from "@apollo/client";
import CreateDiscount from "../../graphql/mutations/createDiscount";
import {useHistory} from "react-router-dom";
import DiscountForm from "../DiscountForm";

interface Props {
}

const DiscountCreate = (props: Props) => {

    const history = useHistory();
    const [createDiscountForm] = Form.useForm();
    const [createDiscount] = useMutation(CreateDiscount);

    return <div>
        <PageHeader
            className="site-page-header"
            onBack={() => {
                history.push('/discounts')
            }}
            title="Create Discount"
        />
        <div className="site-layout-content">
            <DiscountForm submit={true} layout={{labelCol: {span: 9}, wrapperCol: {span: 7}}} form={createDiscountForm} onFinish={(values: any) => {
                createDiscount({
                    variables: {
                        input: values,
                    }
                }).then((result) => {
                    history.push('/discounts')
                }).catch((err: ApolloError) => {
                    return message.error(err.message);
                })
            }}/>
        </div>
    </div>;
}

export default DiscountCreate;
