import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import CustomerList from "../components/CustomerList";
import CustomerCreate from "../components/CustomerCreate";
import ProductList from "../components/ProductList";
import DiscountCodeList from "../components/DiscountCodeList";
import DiscountList from "../components/DiscountList";
import DiscountCreate from "../components/DiscountCreate";
import DiscountValueList from "../components/DiscountValueList";
import ProductPriceList from "../components/ProductPriceList";
import ProductPriceCreate from "../components/ProductPriceCreate";
import ProductCreate from "../components/ProductCreate";

interface Props {
}

function useURLSearchParams() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Routes = (props: Props) => {
    let urlSearchParams = useURLSearchParams();

    return <Switch>
        <Route
            path="/customers/:customerId/product-prices/create"
            component={(props: any) => <ProductPriceCreate
                backUrl={"/customers/" + props.match.params.customerId + "/product-prices"}
                customerId={props.match.params.customerId}
            />}
        />
        <Route
            path="/customers/create"
            component={() => <CustomerCreate
                urlSearchParams={urlSearchParams}
            />}
        />
        <Route
            path="/customers"
            component={() => <CustomerList/>}
        />
        <Route
            path="/discounts/:discountId/product-prices/create"
            component={(props: any) => <ProductPriceCreate
                backUrl={"/discounts/" + props.match.params.discountId + "/product-prices"}
                discountId={props.match.params.discountId}
            />}
        />
        <Route
            path="/discounts/:discountId/product-prices"
            component={(props: any) => <ProductPriceList
                discountId={props.match.params.discountId}
                createUrl={"/discounts/" + props.match.params.discountId + "/product-prices/create"}
            />}
        />
        <Route
            path="/discounts/:discountId/discount-values"
            component={(props: any) => <DiscountValueList
                discountId={props.match.params.discountId}
            />}
        />
        <Route
            path="/discounts/create"
            component={() => <DiscountCreate/>}
        />
        <Route
            path="/discounts"
            component={() => <DiscountList/>}
        />
        <Route
            path="/products/create"
            component={() => <ProductCreate/>}
        />
        <Route
            path="/products"
            component={() => <ProductList/>}
        />
        <Route
            path="/discount-codes"
            children={() => <DiscountCodeList/>}
        />
        <Redirect from="/" to="/customers"/>
    </Switch>;
}

export default Routes;
