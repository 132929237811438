import React from "react";
import {Form, message, PageHeader} from "antd";
import {ApolloError, useMutation} from "@apollo/client";
import CreateProduct from "../../graphql/mutations/createProduct";
import {useHistory} from "react-router-dom";
import ProductForm from "../ProductForm";

interface Props {
}

const ProductCreate = (props: Props) => {

    const history = useHistory();
    const [createProductForm] = Form.useForm();
    const [createProduct] = useMutation(CreateProduct);

    return <div>
        <PageHeader
            className="site-page-header"
            onBack={() => {
                history.push('/products')
            }}
            title="Create Product"
        />
        <div className="site-layout-content">
            <ProductForm submit={true} layout={{labelCol: {span: 9}, wrapperCol: {span: 7}}} form={createProductForm} onFinish={(values: any) => {
                createProduct({
                    variables: {
                        input: values
                    }
                }).then((result) => {
                    history.push('/products')
                }).catch((err: ApolloError) => {
                    return message.error(err.message);
                })
            }}/>
        </div>
    </div>;
}

export default ProductCreate;
