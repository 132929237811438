import gql from "graphql-tag";

export default gql`
    query ($start: Int, $limit: Int, $search: String, $filter: ProductPriceListFilterInput) {
        productPriceList (start: $start, limit: $limit, search: $search, filter: $filter) {
            total
            productPrices {
                id
                insert
                update
                name
                catalogNo
                price
                currency
                product {
                    id
                }
            }
        }
    }
`;
