import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";

export default (user: any) => {
    const httpLink = new HttpLink({
        uri: '/graphql',
        credentials: 'include'
    });

    const authLink = setContext((_request, {headers}) => {
        // get the authentication token from local storage if it exists
        // const rootReducer = localStorage.getItem('persist:root');
        // const token = _.get(JSON.parse(rootReducer),'authReducer.token');
        // return the headers to the context so httpLink can read them,

        return {
            headers: {
                ...headers,
                "Accept": "application/json",
                "Accept-Language": "de-DE,de;q=0.9",
                "Authorization": "Bearer " + user.accessToken,
            }
        }
    });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });
};