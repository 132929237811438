import React from "react";
import {Button, Form, FormInstance, Input, InputNumber} from "antd";
import * as _ from "lodash";
import {Product} from "../ProductList";
import {CurrencySelect, DiscountCodeSelect} from "../Form";

interface FormLayout {
    labelCol: object,
    wrapperCol: object
}

interface Props {
    form: FormInstance,
    product?: Product,
    onFinish?: Function,
    submit?: boolean,
    layout: FormLayout
}

const ProductPriceForm = (props: Props) => {

    const form = props.form;

    let initialValue = {};

    if (props.product) {
        initialValue = {
            catalogNo: _.get(props, 'product.catalogNo'),
            name: _.get(props, 'product.name'),
            discountCodeId: _.get(props, 'product.discountCode.id'),
            price: _.get(props, 'product.price'),
            currency: _.get(props, 'product.currency'),
        };
    }

    const onFinish = (values: any) => {
        if (typeof props.onFinish === 'function') {
            props.onFinish(values);
        }
    };

    return <Form {...props.layout} form={form} onFinish={onFinish} initialValues={initialValue}>
        <Form.Item label="Catalog No" name='catalogNo' rules={[{required: true, message: 'Please input the catalog no!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Name" name='name' rules={[{required: true, message: 'Please input the name!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Discount Code" name='discountCodeId' rules={[{required: true, message: 'Please input the discount code!'}]}>
            <DiscountCodeSelect/>
        </Form.Item>
        <Form.Item label="Price" name='price' rules={[{required: true, message: 'Please input the price!'}]}>
            <InputNumber type='number' step='0.01' style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item label="Currency" name='currency' rules={[{required: true, message: 'Please input the currency!'}]}>
            <CurrencySelect/>
        </Form.Item>
        {props.submit ? <Form.Item wrapperCol={{offset: 9, span: 7}}>
            <Button type="primary" htmlType="submit">
                Create Product
            </Button>
        </Form.Item> : ''}
    </Form>;
}

export default ProductPriceForm;
