import React from 'react';
import {Menu, MenuProps} from "antd";
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";
import {getUser} from "../../redux/selectors/user";
import KeycloakService from "../../service/keycloak";

interface Props {
}

function MainNavigation(props: Props) {

    const history = useHistory();
    const {pathname} = useLocation();
    const user = useSelector(getUser);

    const menuItems = [{
        key: 'customers',
        label: 'Customers'

    }, {
        key: 'discounts',
        label: 'Discounts'

    }, {
        key: 'products',
        label: 'Products'
    }, {
        key: 'discount-codes',
        label: 'Discount Codes'
    }, {
        key: 'user',
        label: user.name,
        style: {
            marginLeft: 'auto'
        },
        children: [{
            key: 'logout',
            label: 'Logout'
        }]
    }]

    const selectedKeys = menuItems.filter((item) => {
        return pathname.startsWith('/' + item.key)
    }).map((item) => item.key);

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === 'logout') {
            KeycloakService.getInstance().logout({
                redirectUri: window.location.href
            });
        } else {
            history.push('/' + e.key)
        }
    };

    return <Menu onClick={onClick} theme={'dark'} selectedKeys={selectedKeys} mode="horizontal" items={menuItems}/>;
}

export default MainNavigation;