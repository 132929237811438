import gql from "graphql-tag";

export default gql`
    mutation ($productId: ID!, $discountId: ID, $customerId: ID, $input: ProductPriceInput!) {
        createProductPrice (productId: $productId, discountId: $discountId, customerId: $customerId, input: $input) {
            id
            insert
            update
            catalogNo
            name
        }
    }
`;
