import React, {useCallback, useState} from "react";
import {Col, Input, Row, Table} from "antd";
import DiscountCodeListListQuery from "./../../graphql/queries/discountCodeList";
import * as _ from "lodash";
import {useQuery} from "@apollo/client";

interface Props {
}

const DiscountCodeList = (props: Props) => {

    const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useState<string>('');
    const [start, setStart] = useState(0);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);

    const handler = useCallback(_.debounce((value) => {
        setCurrentPage(1);
        setStart(0);
        setQuery(value);
    }, 500), []);

    const discountCodeListListQuery = useQuery(DiscountCodeListListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: start,
            limit: limit,
            search: query
        }
    })

    return <div>
        <Row style={{margin: '0 0 20px 0'}}>
            <Col span={18}>
            </Col>
            <Col span={6}>
                <Input placeholder="Search Discount Code" onChange={(e) => {
                    handler(e.target.value);
                    setSearchValue(e.target.value)
                }} value={searchValue}/>
            </Col>
        </Row>

        <Table
            loading={discountCodeListListQuery.loading}
            pagination={{
                size: 'small',
                defaultPageSize: limit,
                total: _.get(discountCodeListListQuery, 'data.discountCodeList.total', []),
                current: currentPage,
                onChange: (page, pageSize) => {
                    pageSize = (typeof pageSize !== 'undefined') ? pageSize : limit;
                    setCurrentPage(page);
                    setStart(page * pageSize - pageSize);
                    setLimit(pageSize);
                }
            }}
            dataSource={_.get(discountCodeListListQuery, 'data.discountCodeList.discountCodes', [])}
            rowKey={'id'}
            columns={[{
                width: 50,
                dataIndex: 'code',
                title: 'Code',
            }, {
                dataIndex: 'name',
                title: 'Name',
            }]}
        />
    </div>;
}

export default DiscountCodeList;
