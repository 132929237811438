import gql from "graphql-tag";

export default gql`
    query ($discountId: ID!) {
        discount (discountId: $discountId) {
            id
            name
            discountValueList {
                total
                discountValues {
                    id
                    insert
                    update
                    discountCode {
                        id
                        code
                        name
                    }
                    value
                }
            }
        }
    }
`;
