import gql from "graphql-tag";

export default gql`
    mutation ($productPriceId: ID!, $input: ProductPriceInput!) {
        updateProductPrice (productPriceId: $productPriceId, input: $input) {
            id
            insert
            update
            name
        }
    }
`;
