import gql from "graphql-tag";

export default gql`
    mutation ($customerId: ID!, $input: CustomerInput!) {
        updateCustomer (customerId: $customerId, input: $input) {
            id
            insert
            update
            country
            customerId
            name
            currency
        }
    }
`;
