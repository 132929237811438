import React, {useState} from "react";
import {Button} from "antd";
import {useMutation} from "@apollo/client";
import GenerateDiscountExport from "../../graphql/mutations/generateDiscountExport";

interface Props {
    discountId: string
}

const GenerateDiscountExportButton = (props: Props) => {

    const [loading, setLoading] = useState(false);

    const [generateDiscountExport] = useMutation(GenerateDiscountExport);

    const onClick = () => {
        setLoading(true);
        generateDiscountExport({
            variables: {
                discountId: props.discountId,
            }
        }).then((result) => {
            setLoading(false);
        })
    };

    return <Button onClick={() => onClick()} loading={loading}>Generate Export</Button>;
}

export default GenerateDiscountExportButton;
