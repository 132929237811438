import gql from "graphql-tag";

export default gql`
    query ($start: Int, $limit: Int, $search: String) {
        discountCodeList (start: $start, limit: $limit, search: $search) {
            total
            discountCodes {
                id
                insert
                update
                code
                name
            }
        }
    }
`;
