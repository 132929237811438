import React, {useCallback, useState} from "react";
import {Badge, Button, Col, Form, Input, message, Modal, Popconfirm, Row, Table} from "antd";
import ProductPriceListQuery from "./../../graphql/queries/productPriceList";
import * as _ from "lodash";
import {ApolloError, useMutation, useQuery} from "@apollo/client";
import {useHistory} from "react-router-dom";
import UpdateProductPrice from "../../graphql/mutations/updateProductPrice";
import DeleteProductPrice from "../../graphql/mutations/deleteProductPrice";
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import Moment from 'moment';
import ProductPriceForm from "../ProductPriceForm";
import CurrenciesQuery from "../../graphql/queries/currencies";

Moment.locale('en');

interface Props {
    createUrl: string,
    customerId?: string
    discountId?: string
}

export interface ProductPrice {
    id: string,
    insert: string,
    update: string,
    catalogNo: string
    name: string,
    price: number,
    currency: string
}

interface Filter {
    currency: Array<string>
}

const ProductPriceList = (props: Props) => {

    const [searchValue, setSearchValue] = useState('');
    const [query, setQuery] = useState<string>('');
    const [start, setStart] = useState<number>(0);
    const [limit, setLimit] = useState<number>(25);
    const [currentProductPrice, setCurrentProductPrice] = useState<ProductPrice | undefined>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filter, setFilter] = useState<Filter>();
    const [updateProductPriceForm] = Form.useForm();

    const history = useHistory();

    const [updateProductPrice] = useMutation(UpdateProductPrice);
    const [deleteProductPrice] = useMutation(DeleteProductPrice);

    const showModal = (productPrice: ProductPrice) => {
        setCurrentProductPrice(productPrice);
        setIsModalVisible(true);
    };

    const handleSearch = useCallback(_.debounce((value) => {
        setCurrentPage(1);
        setStart(0);
        setQuery(value);
    }, 500), []);

    const handleOk = () => {
        updateProductPriceForm
            .validateFields()
            .then(values => {
                if (currentProductPrice) {
                    updateProductPrice({
                        variables: {
                            productPriceId: currentProductPrice.id,
                            input: {
                                price: parseFloat(values.price),
                                currency: values.currency
                            },
                        }
                    }).then(() => {
                        setIsModalVisible(false);
                        return productPriceListQuery.refetch();
                    }).catch((err: ApolloError) => {
                        return message.error(err.message);
                    })
                }
            })
            .catch(info => {
                return message.warning(info);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const productPriceListQuery = useQuery(ProductPriceListQuery, {
        fetchPolicy: "network-only",
        variables: {
            start: start,
            limit: limit,
            search: query,
            filter: {
                customerId: props.customerId,
                discountId: props.discountId,
                ...filter
            }
        }
    })

    const currenciesQuery = useQuery(CurrenciesQuery, {
        variables: {}
    });

    if (currenciesQuery.loading) {
        return <></>
    }

    return <div>
        <Row style={{margin: '0 0 20px 0'}}>
            <Col span={18}>
                <Button type="primary" onClick={() => {
                    history.push(props.createUrl);
                }}>Create Product Price</Button>
            </Col>
            <Col span={6}>
                <Input placeholder="Search Product" onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchValue(e.target.value)
                }} value={searchValue}/>
            </Col>
        </Row>

        <Modal title={currentProductPrice?.name} onOk={handleOk} onCancel={handleCancel} visible={isModalVisible} getContainer={false}>
            <ProductPriceForm layout={{labelCol: {span: 6}, wrapperCol: {span: 18}}} form={updateProductPriceForm} productPrice={currentProductPrice}/>
        </Modal>

        <Table
            loading={productPriceListQuery.loading}
            pagination={{
                size: 'small',
                defaultPageSize: limit,
                total: _.get(productPriceListQuery, 'data.productPriceList.total', []),
                current: currentPage,
                onChange: (page, pageSize) => {
                    pageSize = (typeof pageSize !== 'undefined') ? pageSize : limit;
                    setCurrentPage(page);
                    setStart(page * pageSize - pageSize);
                    setLimit(pageSize);
                }
            }}
            dataSource={_.get(productPriceListQuery, 'data.productPriceList.productPrices', [])}
            rowKey={'id'}
            columns={[{
                width: 200,
                title: 'Catalog No',
                dataIndex: 'catalogNo'
            }, {
                title: 'Name',
                dataIndex: 'name',
                render: (text: string, record) => {
                    const productId = _.get(record, 'product.id');
                    return productId ? <Badge status="success" text={text}/> : <Badge status="error" text={text}/>;
                }
            }, {
                width: 250,
                title: 'Price',
                key: 'price',
                dataIndex: 'price',
                render: (price, record) => {
                    return price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: record.currency
                    })
                }
            }, {
                width: 150,
                title: 'Currency',
                key: 'currency',
                dataIndex: 'currency',
                filterMultiple: true,
                filters: _.get(currenciesQuery, 'data.__type.enumValues', []).map((item: any) => {
                    return {
                        text: item.name,
                        value: item.name,
                    }
                }),
                defaultFilteredValue: _.get(currenciesQuery, 'data.__type.enumValues', []).reduce((result: any, value: any) => {
                    console.log(result);
                    result.push(value.name);
                    return result;
                }, []),
            }, {
                width: 200,
                render: (text, record) => <div style={{textAlign: "right"}}>
                    <Button type='default' onClick={() => showModal(record)} style={{margin: '0 10px 0 0'}} icon={<EditOutlined/>}></Button>
                    <Popconfirm
                        title="Are you sure to delete this product price?"
                        onConfirm={() => {
                            deleteProductPrice({
                                variables: {
                                    productPriceId: record.id,
                                }
                            }).then((result) => {
                                return productPriceListQuery.refetch();
                            }).catch((err: ApolloError) => {
                                return message.error(err.message);
                            })
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type='primary' danger icon={<DeleteOutlined/>}></Button>
                    </Popconfirm>
                </div>
            }]}
            onChange={(pagination, filters: any, sorter, extra) => {
                if (extra.action === 'filter') {
                    setFilter(filters)
                }
            }}
        />
    </div>;
}

export default ProductPriceList;
