import React from "react";
import {Button, Form, FormInstance, Input, Select, Spin} from "antd";
import {useQuery} from "@apollo/client";
import DiscountListQuery from "../../graphql/queries/discountList";
import * as _ from "lodash";
import {Customer} from "../CustomerList";
import {CountrySelect, CurrencySelect} from "../Form";

interface FormLayout {
    labelCol: object,
    wrapperCol: object
}

interface Props {
    form: FormInstance,
    customer?: Customer,
    customerId?: string | null,
    onFinish?: Function,
    submit?: boolean,
    layout: FormLayout
}

const CustomerForm = (props: Props) => {

    const form = props.form;

    let initialValue = {};

    if (props.customerId && props.customerId.match(/[a-zA-Z]{2}-.*/g)) {
        let customerIdPieces = props.customerId.split('-');
        initialValue = {
            country: customerIdPieces.length > 1 ? customerIdPieces[0].toUpperCase() : null,
            customerId: customerIdPieces.length > 1 ? customerIdPieces[1] : props.customerId,
        };
    }

    if (props.customer) {
        initialValue = {
            country: _.get(props, 'customer.country'),
            customerId: _.get(props, 'customer.customerId'),
            name: _.get(props, 'customer.name'),
            discountId: _.get(props, 'customer.discount.id'),
            currency: _.get(props, 'customer.currency'),
        };
    }

    const onFinish = (values: any) => {
        if (typeof props.onFinish === 'function') {
            props.onFinish(values);
        }
    };

    const discountListQuery = useQuery(DiscountListQuery, {
        fetchPolicy: "network-only",
        variables: {}
    });

    // if (discountListQuery.loading) {
    //     return <div style={{textAlign: "center", marginTop: '50px'}}><Spin/></div>
    // }

    return <Form {...props.layout} form={form} onFinish={onFinish} initialValues={initialValue}>
        <Form.Item label="Country" name='country' rules={[{required: true, message: 'Please input the country!'}]}>
            <CountrySelect/>
        </Form.Item>
        <Form.Item label="Customer ID" name='customerId' rules={[{required: true, message: 'Please input the customer id!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Name" name='name' rules={[{required: true, message: 'Please input the customer name!'}]}>
            <Input/>
        </Form.Item>
        <Form.Item label="Discount" name='discountId' rules={[{required: false, message: 'Please input the country!'}]}>
            <Select
                loading={discountListQuery.loading}
                showSearch
                placeholder="Leave blank to create a new discount list"
                filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare((optionB!.children as unknown as string).toLowerCase())
                }
            >
                {_.get(discountListQuery, 'data.discountList.discounts', []).map((item: any) => {
                    return <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                })}
            </Select>
        </Form.Item>
        <Form.Item label="Currency" name='currency' rules={[{required: true, message: 'Please input the currency!'}]}>
            <CurrencySelect/>
        </Form.Item>
        {props.submit ? <Form.Item wrapperCol={{offset: 9, span: 7}}>
            <Button type="primary" htmlType="submit">
                Create Customer
            </Button>
        </Form.Item> : ''}
    </Form>;
}

export default CustomerForm;
