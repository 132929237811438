import gql from "graphql-tag";

export default gql`
    mutation ($productId: ID!, $input: ProductInput!) {
        updateProduct (productId: $productId, input: $input) {
            id
            insert
            update
            name
        }
    }
`;
