import gql from "graphql-tag";

export default gql`
    mutation ($discountId: ID!, $input: DiscountInput!) {
        updateDiscount (discountId: $discountId, input: $input) {
            id
            insert
            update
            name
        }
    }
`;
